import React from "react"
import Workplace from "../components/workplace"

const Workplaces = () => {
  return (
    <div className="marginTop">
      <Workplace
        title="Software Developer (80%)"
        company="Genossenschaft Migros Aare"
        timeframe="04.2021 - Present"
      >
        <ul>
          <li>
            Leading the development and operational day-to-day business of the
            innovation project "Beratungshero"
          </li>
          <li>
            Responsibility for technical KPIs like uptime or call-trough-rate.
            Taking measures to stabilize the KPIs.
          </li>
          <li>
            Refining bussiness requirements, planing the development work and
            providing roadmaps to business stakeholders
          </li>
          <li>
            Supporting business stakeholders in technical pre-sales of new
            clients
          </li>
        </ul>
      </Workplace>
      <Workplace
        title="Software Developer (70%)"
        company="Nothing AG"
        timeframe="12.2019 - 03.2021"
      >
        <ul>
          <li>
            Leading the future strategy of development and digital
            infrastructure circles
          </li>
          <li>Development of Frontend applications</li>
          <li>
            Supporting business acquisitions and project coordination with
            technical inputs and estimations
          </li>
        </ul>
      </Workplace>

      <Workplace
        title="Co-Owner (Part-time)"
        company="Livemotion GmbH"
        timeframe="2017 - Present"
      >
        <ul>
          <li>Building a live video production business</li>
          <li>
            Writing applications with a goal to support live video productions
            with data
          </li>
        </ul>
      </Workplace>
      <Workplace
        title="Application Engineer (60% - 80%)"
        company="Unic AG"
        timeframe="05.2015 - 08.2019"
      >
        <ul>
          <li>
            Technical responsibility for Sitecore projects and release
            management
          </li>
          <li>Development of Sitecore solutions</li>
          <li>
            Assistance to project manager in planning as well as offer writing
          </li>
          <li>
            Assessment of job applications, execution of recruiting interviews,
            with having a functional employment decision
          </li>
          <li>
            Development of PowerShell tools for processes of automated building,
            testing and deployment of Sitecore solutions
          </li>
          <li>
            Configuration, troubleshooting and testing of TeamCity build
            pipelines
          </li>
        </ul>
      </Workplace>
      <Workplace
        title="Apprentice in informatics"
        company="Unic AG"
        timeframe="08.2010 - 07.2014"
      >
        <ul>
          <li>
            Applied learning of all professional Software Development aspects,
            as well as the basics of project management
          </li>
          <li>Development of SharePoint customizations</li>
          <li>Development of Applications with ASP.NET Web Forms</li>
        </ul>
      </Workplace>
    </div>
  )
}

export default Workplaces
